import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const TextField = loadable(() => import('/src/components/Form/TextField'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))

const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required')
})

const ClaimVoucherForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    return (
        <Formik
            initialValues={{
                firstname: '',
                lastname: '',
                email: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'claim-voucher-form',
                    firebaseData: firebaseData,
                    confirmationEmail: 'michelle.dinan@protectline.co.uk',
                    emailTemplate: 'claim-voucher-form-template',
                    redirect: '/claim-voucher-success'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FieldSet>
                        <FieldSpacer grow={'half'}>
                            <TextField
                                label={'First name'}
                                name={'firstname'}
                                placeholder={'e.g. Emma'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'half'}>
                            <TextField
                                label={'Last name'}
                                name={'lastname'}
                                placeholder={'e.g. Jones'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FieldSpacer>
                    </FieldSet>

                    <FormRow>
                        <TextField
                            label={'Email address'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g. name@email.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Claim Voucher'}
                            sentText={'Submitted!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

ClaimVoucherForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

ClaimVoucherForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default ClaimVoucherForm
